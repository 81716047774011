import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";

const TestimonialsTemplate = ({ title, content }) => {

  return (
    <div className="section">
    <div className="w-full max-w-screen-xl px-6 mx-auto">
      <div className={`text-center md:text-left pt-8 pb-12 md:py-16`}>
        <h1 className="lg:pr-12">{title}</h1>
      </div>
    </div>
    <div className={`mx-auto max-w-screen-xl w-full px-6`}>
      <div className={`border-green border py-20 text-center`}>
        <div className={`max-w-lg m-auto px-8 md:px-0`}>
        <div className={`md:text-[22px] contents font-medium leading-8`}>
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

const TestimonialsPage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Seo {...frontmatter} />
      <TestimonialsTemplate
        title={frontmatter.title}
        content={data.markdownRemark.html}
      />
    </Layout>
  );
};

export const testimonialsQuery = graphql`
  query TestimonialsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

export default TestimonialsPage;